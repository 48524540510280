import { useState } from 'react'
import { t } from '../utils/i18n'
import styles from './_announcement.module.scss'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useMutation } from '@apollo/client'
import Link from 'next/link'
import useErrorHandling from '../utils/hooks/useErrorHandling'
import { useAuth } from '../utils/auth'
import Image from 'next/legacy/image'
import { PROCESS_ANNOUNCEMENT_ACTION_MUTATION } from '../gql/mutations/process_announcement_action_mutation'
import { TOGGLE_ANNOUNCEMENT_AS_READ_MUTATION } from '../gql/mutations/toggle_announcement_as_read_mutation'
import { Announcement, User } from '../__generated__/graphql'

interface DropdownProps {
  announcements: Announcement[]
  isRemoveReadFilter: boolean
}

interface NoticeProps {
  announcement: Announcement
  style?: string
}

export enum AnnouncementType {
  setlistInvited = 'invited_to_setlist',
  setlistAccepted = 'accepted_setlist_invitation',
  setlistDeclined = 'declined_setlist_invitation',
  teamInvited = 'invited_to_team',
  teamAccepted = 'accepted_team_invitation',
  teamDeclined = 'declined_team_invitation',
  leftTeam = 'left_team'
}

enum InvitationActionEnum {
  Accept = 'accept',
  Decline = 'decline'
}

const NotificationWrap = ({ children, path }) => {
  if (!path) return <>{children}</>

  return <Link href={path}>{children}</Link>
}

export const Notice: React.FC<NoticeProps> = ({ announcement, style }) => {
  const errorHandling = useErrorHandling()

  const noticeType = {
    isInvitedToSetlist:
      announcement.announcementType === AnnouncementType.setlistInvited,
    isAcceptedSetlist:
      announcement.announcementType === AnnouncementType.setlistAccepted,
    isDeclinedSetlist:
      announcement.announcementType === AnnouncementType.setlistDeclined,
    isInvitedToTeam:
      announcement.announcementType === AnnouncementType.teamInvited,
    isAcceptedTeam:
      announcement.announcementType === AnnouncementType.teamAccepted,
    isDeclinedTeam:
      announcement.announcementType === AnnouncementType.teamDeclined &&
      !Boolean(announcement.destroyedMemberName),
    isNewbieDeclinedInvite:
      announcement.announcementType === AnnouncementType.teamDeclined &&
      Boolean(announcement.destroyedMemberName),
    isLeftTeam:
      announcement.announcementType === AnnouncementType.leftTeam &&
      !Boolean(announcement.destroyedMemberName),
    isMemberLeftHimself:
      announcement.announcementType === AnnouncementType.leftTeam &&
      Boolean(announcement.destroyedMemberName),
    isActionRequired: announcement.requiredAction,
    isSetlistInform:
      (announcement.announcementType === AnnouncementType.setlistAccepted ||
        announcement.announcementType === AnnouncementType.setlistDeclined) &&
      !announcement.requiredAction,
    isTeamInform:
      (announcement.announcementType === AnnouncementType.teamAccepted ||
        announcement.announcementType === AnnouncementType.teamDeclined) &&
      !announcement.requiredAction &&
      !Boolean(announcement.destroyedMemberName)
  }

  const noticeTitle = () => {
    switch (true) {
      case noticeType.isInvitedToSetlist && noticeType.isActionRequired:
        return t('You are invited to a setlist')
      case noticeType.isAcceptedSetlist && noticeType.isActionRequired:
        return t('You’ve been added to a setlist')
      case noticeType.isDeclinedSetlist && noticeType.isActionRequired:
        return t('You’ve declined setlist invitation')
      case noticeType.isInvitedToTeam && noticeType.isActionRequired:
        return t('You’re invited to join a team')
      case noticeType.isAcceptedTeam && noticeType.isActionRequired:
        return t('You’ve been added to a team')
      case noticeType.isDeclinedTeam && noticeType.isActionRequired:
        return t('You’ve declined team invitation')
      case noticeType.isLeftTeam:
        return `${announcement.changeTeamInvitation?.invitee.firstName} left your team`
      case noticeType.isMemberLeftHimself:
        return `${announcement.destroyedMemberName} left your team`
      case noticeType.isSetlistInform:
        return `${announcement.setlistMember.user.firstName} ${announcement.setlistMember.status} a setlist invitation`
      case noticeType.isTeamInform:
        return `${announcement.changeTeamInvitation?.invitee.firstName} ${announcement.changeTeamInvitation?.status} a team invitation`
      case noticeType.isNewbieDeclinedInvite:
        return `${announcement.destroyedMemberName} declined a team invitation`
    }
  }

  const [processInvitation] = useMutation(
    PROCESS_ANNOUNCEMENT_ACTION_MUTATION,
    {
      onError: errorHandling,
      update: (_cache, result) => {
        const { announcement: notice } = result.data.processAnnouncementAction
        setCurrentUser({
          ...currentUser,
          announcements: currentUser.announcements.map((item: Announcement) =>
            item.id === notice.id ? notice : item
          )
        } as User)
      }
    }
  )

  const [toggleRead] = useMutation(TOGGLE_ANNOUNCEMENT_AS_READ_MUTATION, {
    onError: errorHandling,
    update: (_cache, result) => {
      const { announcement: notice } = result.data.toggleAnnouncementAsRead
      setCurrentUser({
        ...currentUser,
        announcements: currentUser.announcements.map((item: Announcement) =>
          item.id === notice.id ? notice : item
        )
      } as User)
    }
  })

  const [isAnimateAccept, setIsAnimateAccept] = useState<boolean>(false)
  const [isAnimateDecline, setIsAnimateDecline] = useState<boolean>(false)
  const handlerAction = (action: InvitationActionEnum) => {
    processInvitation({ variables: { id: announcement.id, action: action } })
  }

  const { currentUser, setCurrentUser, isMusician } = useAuth()

  const SUBJECT_PATHS = {
    invited_to_setlist: `/setlists/${announcement.setlist?.id}`,
    accepted_setlist_invitation: `/setlists/${announcement.setlist?.id}`,
    declined_setlist_invitation: `/setlists/${announcement.setlist?.id}`,
    invited_to_team: !isMusician ? '/team' : null,
    accepted_team_invitation: !isMusician ? '/team' : null,
    declined_team_invitation: !isMusician ? '/team' : null,
    left_team: !isMusician ? '/team' : null
  }

  const subjectPath = SUBJECT_PATHS[announcement.announcementType]

  if (!(announcement.announcementType in SUBJECT_PATHS)) {
    throw new Error(`${announcement.announcementType} is not registered`)
  }

  return (
    <div
      className={classnames(styles.notice, style, {
        [styles.noticeInactive]: announcement.read
      })}
    >
      <div
        className={classnames(styles.noticeIndicator, {
          [styles.noticeIndicatorInactive]: announcement.read
        })}
        onClick={() => toggleRead({ variables: { id: announcement.id } })}
      />
      <div
        className={classnames(
          styles.noticeText,
          {
            [styles.noticeTextNotify]:
              noticeType.isSetlistInform ||
              noticeType.isTeamInform ||
              noticeType.isLeftTeam
          },
          {
            [styles.noticeTextRead]:
              (noticeType.isAcceptedSetlist ||
                noticeType.isAcceptedTeam ||
                noticeType.isDeclinedSetlist ||
                noticeType.isDeclinedTeam) &&
              noticeType.isActionRequired
          }
        )}
      >
        {(noticeType.isSetlistInform ||
          (noticeType.isTeamInform && !noticeType.isNewbieDeclinedInvite) ||
          (noticeType.isLeftTeam && !noticeType.isMemberLeftHimself)) && (
          <Image
            alt={
              noticeType.isSetlistInform
                ? `${announcement.user.firstName} ${announcement.user.lastName}`
                : `${announcement.changeTeamInvitation?.invitee.firstName} ${announcement.changeTeamInvitation?.invitee.lastName}`
            }
            src={
              noticeType.isSetlistInform
                ? announcement.setlistMember.user.picture
                : announcement.changeTeamInvitation?.invitee.picture
            }
            height={36}
            width={36}
          />
        )}
        <NotificationWrap path={subjectPath}>
          <p className={styles.noticeTitle}>
            <span>{noticeTitle()}</span>
            {(noticeType.isInvitedToSetlist ||
              noticeType.isAcceptedSetlist ||
              noticeType.isDeclinedSetlist) &&
              noticeType.isActionRequired && (
                <div>{announcement.setlist.name}</div>
              )}
          </p>
          {(noticeType.isInvitedToTeam ||
            noticeType.isAcceptedTeam ||
            noticeType.isDeclinedTeam) &&
            noticeType.isActionRequired && (
              <p className={styles.noticeSubtitle}>
                {announcement.changeTeamInvitation?.team.organization}
              </p>
            )}
        </NotificationWrap>
      </div>
      {noticeType.isSetlistInform ||
      noticeType.isTeamInform ||
      noticeType.isNewbieDeclinedInvite ? (
        <div className={styles.noticeIcon}>
          <FontAwesomeIcon
            icon={
              noticeType.isAcceptedSetlist || noticeType.isAcceptedTeam
                ? faCheckCircle
                : faTimesCircle
            }
            className={classnames(
              {
                [styles.accepted]:
                  noticeType.isAcceptedSetlist || noticeType.isAcceptedTeam
              },
              {
                [styles.declined]:
                  noticeType.isDeclinedSetlist ||
                  noticeType.isDeclinedTeam ||
                  noticeType.isNewbieDeclinedInvite
              }
            )}
          />
        </div>
      ) : (
        (((noticeType.isInvitedToSetlist || noticeType.isInvitedToTeam) &&
          noticeType.isActionRequired) ||
          noticeType.isAcceptedSetlist ||
          noticeType.isDeclinedSetlist) && (
          <div
            className={classnames(styles.noticeButtons, {
              [styles.noticeButtonAcceptAnimate]: isAnimateAccept,
              [styles.noticeButtonDeclineAnimate]: isAnimateDecline,
              [styles.noticeButtonAccepted]: noticeType.isAcceptedSetlist,
              [styles.noticeButtonDeclined]: noticeType.isDeclinedSetlist
            })}
          >
            <button
              className={classnames(
                styles.noticeButton,
                styles.noticeButtonAccept
              )}
              onClick={(e) => {
                e.preventDefault()
                setIsAnimateAccept(true)
              }}
              onAnimationEnd={() => {
                if (noticeType.isAcceptedSetlist) return
                handlerAction(InvitationActionEnum.Accept)
              }}
            >
              <span>
                {noticeType.isAcceptedSetlist ? t('Accepted') : t('Accept')}
              </span>
            </button>
            <button
              className={classnames(
                styles.noticeButton,
                styles.noticeButtonDecline
              )}
              onClick={(e) => {
                e.preventDefault()
                setIsAnimateDecline(true)
              }}
              onAnimationEnd={() => {
                if (noticeType.isDeclinedSetlist) return
                handlerAction(InvitationActionEnum.Decline)
              }}
            >
              <span>
                {noticeType.isDeclinedSetlist ? t('Declined') : t('Decline')}
              </span>
            </button>
          </div>
        )
      )}
    </div>
  )
}

const MainAnnouncementsDropdown: React.FC<DropdownProps> = ({
  announcements,
  isRemoveReadFilter
}) => {
  const notRead = announcements.filter((notice: Announcement) => !notice.read)
  const prepareAnnouncementList = (announcements: Announcement[]) => {
    const sortByDate = (filterable: Announcement[]) =>
      filterable
        .slice()
        .sort((a, b) =>
          a.createdAt < b.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0
        )
    const announcementList = isRemoveReadFilter
      ? sortByDate(notRead)
      : sortByDate(announcements)

    return announcementList
  }

  return (
    <div className={styles.notificationItems}>
      {prepareAnnouncementList(announcements).map(
        (announcement: Announcement) => (
          <Notice key={announcement.id} announcement={announcement} />
        )
      )}
    </div>
  )
}

export default MainAnnouncementsDropdown
